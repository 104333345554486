import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: 25px;
  }
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const HeroImage = styled.img`
  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const Section = styled.div``;
const SectionHeading = styled.h3``;
const SectionList = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const SectionItem = styled.a`
  padding: 20px;
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* align-items: center; */
  border-radius: 5px;
  background-color: var(--translucent);
  width: 27vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const ItemLogo = styled.img`
  height: 35px;
  border-radius: 10px;
`;
const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ItemHeading = styled.div`
  font-size: 30px;
  font-weight: 900;
`;
const ItemDescription = styled.div`
  font-family: "Prociono", serif;
  font-size: 18px;
  opacity: 0.8;
`;

const IntemLogoPart = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
`;

export default function Home() {
  return (
    <Container>
      <Nav />

      <HeroSection>
        <HeroText>
          <HeroHeading>Simplicity & Focus</HeroHeading>
          <HeroDescription>
            We are a software company, but our roots are in design, which allows
            us to create powerful products without compromising on elegance. Our
            mission is to create products and experiences that empower you to
            chase your dreams.
          </HeroDescription>
        </HeroText>

        <HeroImage src="/heroImage.png" />
      </HeroSection>

      <Section>
        <SectionHeading>Software Products</SectionHeading>
        <SectionList>
          <SectionItem target="_blank" href="https://paratime.app">
            <IntemLogoPart>
              <ItemLogo src="/paratime.svg" />
              <ItemHeading>Paratime</ItemHeading>
            </IntemLogoPart>
            <ItemText>
              <ItemDescription>
                Social Time Tracker For VsCode. Find out how many hours you have
                coded vs your friends.
              </ItemDescription>
            </ItemText>
          </SectionItem>
          <SectionItem target="_blank" href="https://holda.upon.one">
            <IntemLogoPart>
              <ItemLogo src="/holda.svg" />
              <ItemHeading>Holda</ItemHeading>
            </IntemLogoPart>

            <ItemText>
              <ItemDescription>
                Minimal, all in one productivity tool with todos, sub-todos,
                notes, encrypted diary & pomodoro with stat.
              </ItemDescription>
            </ItemText>
          </SectionItem>
          <SectionItem target="_blank" href="https://inwire.app">
            <IntemLogoPart>
              <ItemLogo src="/inwire.svg" />
              <ItemHeading>Inwire</ItemHeading>
            </IntemLogoPart>

            <ItemText>
              <ItemDescription>
                Freelance platform with near 0% commission & Social network for
                creatives
              </ItemDescription>
            </ItemText>
          </SectionItem>
        </SectionList>
      </Section>
      <Footer />
    </Container>
  );
}

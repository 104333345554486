import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;
`;
const HeroImage = styled.img``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40vh;
`;
const SectionHeading = styled.h1`
  letter-spacing: -2px;
  font-weight: 900;
  font-size: 45px;
`;

const SectionDesc = styled.div`
  font-family: "Prociono", serif;
  text-align: center;
  width: 62vw;
  font-size: 18px;
`;

const Link = styled.a`
  text-decoration: underline;
  color: #111;
`;
export default function AboutUs() {
  return (
    <Container>
      <Nav />

      <Section>
        <SectionHeading>About Us</SectionHeading>
        <SectionDesc>
          We are a software company, but our roots are in design, which allows
          us to create powerful products without compromising on elegance. Our
          mission is to create products and experiences that empower you to
          chase your dreams. We are based in India.
          <br />
          <br />
          <Link target="_blank" href="https://arnav.upon.one">
            Founder - Arnav Singh
          </Link>
        </SectionDesc>
      </Section>
      <Footer />
    </Container>
  );
}

import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;
`;
const HeroImage = styled.img``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SectionHeading = styled.h1`
  letter-spacing: -2px;
  font-weight: 900;
  font-size: 45px;
`;

const SectionDesc = styled.div`
  font-family: "Prociono", serif;
  text-align: center;
  width: 62vw;
  font-size: 18px;
`;

export default function Support() {
  return (
    <Container>
      <Nav />

      <Section>
        <SectionHeading>Support</SectionHeading>
        <SectionDesc>
          You can contact us on uponinteractive@outlook.com
        </SectionDesc>
      </Section>
      <Footer />
    </Container>
  );
}

import styled from "styled-components";
import Nav from "./Nav";
import { AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import copy from "copy-to-clipboard";

import Footer from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;
`;
const HeroImage = styled.img``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SectionHeading = styled.h1`
  letter-spacing: -2px;
  font-weight: 900;
  font-size: 45px;
`;
const SectionList = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`;
const SectionItem = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  align-items: center;
  border-radius: 5px;
  background-color: var(--translucent);
  width: auto;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const ItemLogo = styled.div`
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const ItemHeading = styled.div`
  font-size: 18px;
  font-weight: 900;
`;
const ItemDescription = styled.div`
  font-family: "Prociono", serif;
  font-size: 18px;
`;

export default function ContactUs() {
  return (
    <Container>
      <Nav />
      <Section>
        <SectionHeading>Contact Us</SectionHeading>
        <SectionList>
          <SectionItem onClick={openInsta}>
            <ItemLogo>
              <AiOutlineInstagram />
            </ItemLogo>
            <ItemHeading>@upon_interactive</ItemHeading>
          </SectionItem>
          <SectionItem onClick={copyMailAddress}>
            <ItemLogo>
              <AiOutlineMail />
            </ItemLogo>
            <ItemHeading>uponinteractive@outlook.com</ItemHeading>
          </SectionItem>
        </SectionList>
      </Section>
      <Footer />

      <ToastContainer />
    </Container>
  );

  function openInsta() {
    window.open("https://instagram.com/upon_interactive");
  }

  function copyMailAddress() {
    toast("Copied to clipboard");
    copy("uponinteractive@outlook.com");
  }
}

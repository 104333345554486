import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;
`;
const HeroImage = styled.img``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SectionHeading = styled.h1`
  letter-spacing: -2px;
  font-weight: 900;
  font-size: 45px;
`;

const SectionDesc = styled.div`
  font-family: "Prociono", serif;
  text-align: center;
  width: 62vw;
  font-size: 18px;
`;

export default function TermsOfService() {
  return (
    <Container>
      <Nav />

      <Section>
        <SectionHeading>Terms Of Service</SectionHeading>
        <SectionDesc>
          1. Terms By accessing this Website, accessible from https://upon.one,
          you are agreeing to be bound by these Website Terms and Conditions of
          Use and agree that you are responsible for the agreement with any
          applicable local laws. If you disagree with any of these terms, you
          are prohibited from accessing this site. The materials contained in
          this Website are protected by copyright and trade mark law. <br />{" "}
          <br />
          2. Use License Permission is granted to temporarily download one copy
          of the materials on Upon Interactive's Website for personal,
          non-commercial transitory viewing only. This is the grant of a
          license, not a transfer of title, and under this license you may not:
          modify or copy the materials; use the materials for any commercial
          purpose or for any public display; attempt to reverse engineer any
          software contained on Upon Interactive's Website; remove any copyright
          or other proprietary notations from the materials; or transferring the
          materials to another person or "mirror" the materials on any other
          server. This will let Upon Interactive to terminate upon violations of
          any of these restrictions. Upon termination, your viewing right will
          also be terminated and you should destroy any downloaded materials in
          your possession whether it is printed or electronic format. These
          Terms of Service has been created with the help of the Terms Of
          Service Generator.
          <br /> <br />
          3. Disclaimer All the materials on Upon Interactive's Website are
          provided "as is". Upon Interactive makes no warranties, may it be
          expressed or implied, therefore negates all other warranties.
          Furthermore, Upon Interactive does not make any representations
          concerning the accuracy or reliability of the use of the materials on
          its Website or otherwise relating to such materials or any sites
          linked to this Website.
          <br /> <br /> 4. Limitations Upon Interactive or its suppliers will
          not be hold accountable for any damages that will arise with the use
          or inability to use the materials on Upon Interactive's Website, even
          if Upon Interactive or an authorize representative of this Website has
          been notified, orally or written, of the possibility of such damage.
          Some jurisdiction does not allow limitations on implied warranties or
          limitations of liability for incidental damages, these limitations may
          not apply to you.
          <br /> <br /> 5. Revisions and Errata The materials appearing on Upon
          Interactive's Website may include technical, typographical, or
          photographic errors. Upon Interactive will not promise that any of the
          materials in this Website are accurate, complete, or current. Upon
          Interactive may change the materials contained on its Website at any
          time without notice. Upon Interactive does not make any commitment to
          update the materials.
          <br /> <br /> 6. Links Upon Interactive has not reviewed all of the
          sites linked to its Website and is not responsible for the contents of
          any such linked site. The presence of any link does not imply
          endorsement by Upon Interactive of the site. The use of any linked
          website is at the user's own risk.
          <br /> <br /> 7. Site Terms of Use Modifications Upon Interactive may
          revise these Terms of Use for its Website at any time without prior
          notice. By using this Website, you are agreeing to be bound by the
          current version of these Terms and Conditions of Use. <br /> <br />
          8. Your Privacy Please read our Privacy Policy.
          <br /> <br /> 9. Governing Law Any claim related to Upon Interactive's
          Website shall be governed by the laws of in without regards to its
          conflict of law provisions.
        </SectionDesc>
      </Section>
      <Footer />
    </Container>
  );
}

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
  opacity: 0.5;
  margin-top: 100px;
  border-top: 1px solid var(--translucent);
`;
const BrandLogo = styled.img`
  cursor: pointer;
`;
const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const Link = styled.div`
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default function Footer() {
  let navigation = useNavigate();
  return (
    <Container>
      <Links>
        <Link
          onClick={() => {
            navigation("/privacy-policy");
          }}
        >
          Privacy Policy
        </Link>
        <Link
          onClick={() => {
            navigation("/support");
          }}
        >
          Support
        </Link>
        <Link
          onClick={() => {
            navigation("/terms-of-service");
          }}
        >
          Terms Of Service
        </Link>
        <Link
          onClick={() => {
            navigation("/refund-policy");
          }}
        >
          Refund Policy
        </Link>
      </Links>
    </Container>
  );
}

import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";

const Container = styled.div`
  width: 90vw;
  gap: 100px;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 900;
  letter-spacing: -2px;
`;
const HeroDescription = styled.div`
  font-family: "Prociono", serif;
  width: 38vw;
  font-size: 21px;
`;
const HeroImage = styled.img``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SectionHeading = styled.h1`
  letter-spacing: -2px;
  font-weight: 900;
  font-size: 45px;
  text-align: left;
  width: 62vw;
`;

const SectionDesc = styled.div`
  font-family: "Prociono", serif;
  text-align: left;
  width: 62vw;
  font-size: 18px;
`;

export default function RefundPolicy() {
  return (
    <Container>
      <Nav />

      <Section>
        <SectionHeading>Refund Policy</SectionHeading>
        <SectionDesc>
          <div>
            <span data-custom-class="subtitle">
              Last updated <bdt class="question">June 23, 2023</bdt>
            </span>
            <div align="center" class="MsoNormal">
              <div align="center" class="MsoNormal">
                <br />
              </div>

              <div align="center" class="MsoNormal">
                <br />
              </div>
              <div align="center" class="MsoNormal">
                <span>
                  <br />
                  <a name="_2cipo4yr3w5d"></a>
                </span>
              </div>
            </div>
            <div class="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt class="block-component"></bdt>
              </span>
            </div>
            <div data-custom-class="heading_1">
              <strong>
                <span>REFUNDS</span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div data-custom-class="body_text">
              <span>
                All sales are final and no refund will be issued.
                <bdt class="block-component"></bdt>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div data-custom-class="heading_1">
              <span>
                <strong>QUESTIONS</strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div data-custom-class="body_text">
              <span>
                If you have any questions concerning our return policy, please
                contact us at:
              </span>
            </div>
            <div data-custom-class="body_text">
              <br />
            </div>
            <div data-custom-class="body_text">
              <span>
                <bdt class="block-component"></bdt>
              </span>
            </div>
            <div data-custom-class="body_text">
              <span>
                <bdt class="question">uponinteractive@outlook.com</bdt>
              </span>
            </div>
          </div>
        </SectionDesc>
      </Section>
      <Footer />
    </Container>
  );
}
